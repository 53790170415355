// export const url = 'http://127.0.0.1:8000/'
// export const socket = "ws://127.0.0.1:8000/"
// export const fileUrl = 'http://127.0.0.1:8000'
// export const mediaUrl = 'http://127.0.0.1:8000/media/'


export const url = 'https://api.scoutstack.co.in/'
export const socket = "wss://api.scoutstack.co.in/"
export const fileUrl = 'https://api.scoutstack.co.in'
export const mediaUrl = 'https://api.scoutstack.co.in/media/'

// export const mediaUrl = 'https://dxbyyawaxhzfn.cloudfront.net/media/'

export const tinyApiKey = "0oayfok2hzg7qmvlqyobcb2srkxea21mknqpdqgcbree1opz"
export function numFormatter(num) {
    if (num) {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + ' K'; // convert to K for number from > 1000 < 1 million 
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + ' M'; // convert to M for number from > 1 million 
        } else if (num < 1000) {
            return num; // if value < 1000, nothing to do
        }
    } else {
        return 0;
    }
}

export const userType = {
    admin: 'ADMIN',
    sub_admin: 'SUB ADMIN',
    sales_executive: 'SALES EXECUTIVE',
    sales_manager: 'SALES MANAGER',
    compliance: 'COMPLIANCE',
    researcher: 'RESEARCHER',
    hr: 'HR',
}

export const stateOptions = [
    {
        "key": "AN",
        "name": "Andaman and Nicobar Islands"
    },
    {
        "key": "AP",
        "name": "Andhra Pradesh"
    },
    {
        "key": "AR",
        "name": "Arunachal Pradesh"
    },
    {
        "key": "AS",
        "name": "Assam"
    },
    {
        "key": "BR",
        "name": "Bihar"
    },
    {
        "key": "CG",
        "name": "Chandigarh"
    },
    {
        "key": "CH",
        "name": "Chhattisgarh"
    },
    {
        "key": "DH",
        "name": "Dadra and Nagar Haveli"
    },
    {
        "key": "DD",
        "name": "Daman and Diu"
    },
    {
        "key": "DL",
        "name": "Delhi"
    },
    {
        "key": "GA",
        "name": "Goa"
    },
    {
        "key": "GJ",
        "name": "Gujarat"
    },
    {
        "key": "HR",
        "name": "Haryana"
    },
    {
        "key": "HP",
        "name": "Himachal Pradesh"
    },
    {
        "key": "JK",
        "name": "Jammu and Kashmir"
    },
    {
        "key": "JH",
        "name": "Jharkhand"
    },
    {
        "key": "KA",
        "name": "Karnataka"
    },
    {
        "key": "KL",
        "name": "Kerala"
    },
    {
        "key": "LD",
        "name": "Lakshadweep"
    },
    {
        "key": "MP",
        "name": "Madhya Pradesh"
    },
    {
        "key": "MH",
        "name": "Maharashtra"
    },
    {
        "key": "MN",
        "name": "Manipur"
    },
    {
        "key": "ML",
        "name": "Meghalaya"
    },
    {
        "key": "MZ",
        "name": "Mizoram"
    },
    {
        "key": "NL",
        "name": "Nagaland"
    },
    {
        "key": "OR",
        "name": "Odisha"
    },
    {
        "key": "PY",
        "name": "Puducherry"
    },
    {
        "key": "PB",
        "name": "Punjab"
    },
    {
        "key": "RJ",
        "name": "Rajasthan"
    },
    {
        "key": "SK",
        "name": "Sikkim"
    },
    {
        "key": "TN",
        "name": "Tamil Nadu"
    },
    {
        "key": "TS",
        "name": "Telangana"
    },
    {
        "key": "TR",
        "name": "Tripura"
    },
    {
        "key": "UP",
        "name": "Uttar Pradesh"
    },
    {
        "key": "UK",
        "name": "Uttarakhand"
    },
    {
        "key": "WB",
        "name": "West Bengal"
    }
]

export const permissionOptions = [
    {
        "value": "CATEGORY_PERMISSION",
        "label": "Category | Add, Edit, Delete"
    },
    {
        "value": "PRODUCT_PERMISSION",
        "label": "Product | Add, Edit, Delete"
    },
    {
        "value": "IMPORT_PERMISSION",
        "label": "Lead Import"
    },
    {
        "value": "OWNER_CHANGE_PERMISSION",
        "label": "Owner Change Permission"
    },
    {
        "value": "LEADS_PERMISSION",
        "label": "Lead | Edit, Delete"
    },
    {
        "value": "SERVICE_PERMISSION",
        "label": "Service | Edit, Delete"
    },
    {
        "value": "SERVICE_ADD_PERMISSION",
        "label": "Add a New Service"
    },
    {
        "value": "STAFF_PERMISSION",
        "label": "User | Add, Edit, Delete"
    },
]

export const userSegrigation = [
    {
        "key": "ADMIN",
        "name": "admin"
    },
    {
        "key": "SUB ADMIN",
        "name": "sub_admin"
    },
    {
        "key": "RESEARCHER",
        "name": "researcher"
    },
    {
        "key": "COMPLIANCE",
        "name": "compliance"
    },
    {
        "key": "SALES MANAGER",
        "name": "sales_manager"
    },
    {
        "key": "SALES EXECUTIVE",
        "name": "sales_executive"
    },
    {
        "key": "HR",
        "name": "hr"
    },
]

export const callDetailsWebsite = "www.scoutstack.co.in"
export const callDetailsNumber = "8827979008"
